.filter__form {
  padding: 0 25px 60px;
}

.filter__submit {
  width: 100%;
  margin-bottom: 15px;
}

.filter__reset {
  width: 144px;
}
