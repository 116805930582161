.content {
  flex-grow: 1;
  padding-top: 35px;
  padding-bottom: 100px;
}

.content__top {
  margin-bottom: 35px;

  &--catalog {
    margin-left: 310px;
  
    text-align: center;
  }
}

.content__row {
  display: flex;
  align-items: center;
}

.content__breadcrumbs {
  margin-bottom: 10px;
}

.content__title {
  margin: 10px 0;

  font-weight: 800;
  font-size: 24px;
  line-height: 54px;
  text-transform: uppercase;

  span {
    font-family: $font-main;
    font-weight: 500;
    font-size: 18px;
    vertical-align: top;
    color: #000;
  }
}

.content__info {
  margin-left: 12px;

  font-size: 16px;
  font-weight: 300;
  line-height: 1;
  color: $text-second;
}

.content__catalog {
  display: grid;
  grid-template-columns: 290px 1fr;
  grid-gap: 20px;
  align-items: flex-start;
}