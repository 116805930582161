.dictionary {
  @include ulres;
}

.dictionary__item {
  display: grid;
  grid-template-columns: 170px 1fr;
  grid-gap: 20px;

  font-size: 16px;
  line-height: 24px;

  &:not(:last-child) {
    margin-bottom: 30px;
  }
}

.dictionary__key {
  opacity: 0.6;

  font-weight: 300;
}