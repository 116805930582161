.header {
  background-color: $bg-second;
}

.header__wrapper {
  display: grid;
  grid-template-columns: 380px 1fr  300px 25px;
  grid-column-gap: 40px;
  align-items: flex-end;
  justify-content: space-between;
  padding-top: 23px;
  padding-bottom: 23px;
  border-bottom: 1px solid #e9e9e9;
}

.header__info {
  font-size: 12px;
  text-transform: uppercase;
}

.header__logo {
  display: block;
  width: 190px;
  height: 33px;
  margin: 0 auto;
}

.header__tel {
  margin-left: auto;

  font-size: 14px;
  color: #000;
}

.header__cart {
  position: relative;
  height: 27px;
}

.header__count {
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: $color-primery;
  padding: 3px;
  min-width: 13px;
  height: 13px;
  border-radius: 50%;

  font-size: 7px;
  line-height: 1;
  font-weight: 500;
  color: #fff;
  text-align: center;
}