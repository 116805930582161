.form__block {
  border: none;
  padding: 0;
  margin: 0;
  margin-bottom: 35px;
}

.form__legend {
  margin-bottom: 12px;

  font-family: $font-main;
  font-size: 14px;
  line-height: 1;
  color: #737373;
}

.form__label {
  position: relative;
  display: block;

  color: #222;

  &:not(:last-child) {
    margin-bottom: 25px;
  }

  &--price {
    &::after {
      content: '₽';
      position: absolute;
      top: 32px;
      right: 20px;

      font-size: 16px;
      line-height: 1;
      color: $text-main;
    }
  }

  &--select {
    &::after {
      content: '';
      position: absolute;
      top: 50%;
      right: 20px;
      transform: translateY(-50%);
      width: 14px;
      height: 7px;
      background-image: url('../img/svg/icon-arrow-bottom.svg');
      background-repeat: no-repeat;
      background-size: contain;
    }
  }

  &--small {
    &::after {
      right: 5px;
    }

    select {
      height: 35px;
      padding: 6px 25px 6px 10px;
    }
  }
}

.form__value {
  position: absolute;
  top: 10px;
  left: 20px;

  font-size: 12px;
  line-height: 1;
  color: #737373;
  font-weight: 300;
}

.form__input {
  padding: 28px 45px 13px 20px;
  width: 100%;
  height: 65px;
  border-radius: 1px;
  border: 1px solid transparent;
  background-color: #FAFAFA;
  box-shadow: none;
  transition: $transition;

  color: $text-main;
  font-size: 16px;
  font-family: inherit;
  line-height: 1;

  &:hover,
  &:focus {
    outline: none;
    border-color: #222;
  }

  &--area {
    height: 140px;
    resize: none;
  }
}

.form__select {
  width: 100%;
  height: 52px;
  border-radius: 0;
  border: none;
  appearance: none;
  padding: 14px 35px 14px 18px;

  color: $text-main;
  font-size: 16px;
  font-family: inherit;
  line-height: 1;
}

.form__counter {
  display: flex;
  align-items: center;
  width: 150px;
  height: 70px;
  background-color: #fff;

  button {
    @include inpres;

    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border: 1px solid #E2E2E2;
    flex-shrink: 0;
    width: 37px;
    height: 37px;
    flex-shrink: 0;
    cursor: pointer;
    transition: $transition;

    &:hover,
    &:focus {
      background-color: #222;
      color: #fff
    }
  }

  input {
    padding: 23px 5px;
    height: 70px;
    width: 70px;
    border-radius: 0;
    background-color: transparent;
    border: none;
    box-shadow: none;
  
    color: $text-main;
    font: inherit;
    font-size: 24px;
    line-height: 1;
    text-align: center;
  }
}

.form__error {
  position: absolute;
  bottom: -17px;
  left: 20px;

  font-size: 11px;
  line-height: 14px;
  color: #E02D71;
}

.form__error-block {
  padding: 25px 30px;
  border: 3px solid #E02D71;
  border-radius: 1px;

  h4 {
    margin: 0 0 4px;

    font-weight: 500;
    font-size: 17px;
    line-height: 24px;
    color: #E02D71;
  }

  p {
    margin: 0;
    font-size: 13px;
    color: #000;
  }
}