.pics__wrapper {
  margin-bottom: 20px;
}

.pics__list {
  @include ulres;

  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 20px;
}

.pics__link {
  border-bottom: 3px solid transparent;
  transition: $transition;

  &:hover,
  &:focus {
    border-color: $color-primery;
  }

  &--current {
    border-color: $color-primery;
  }
}