.catalog {
  display: flex;
  flex-direction: column;
  align-self: stretch; 
}

.catalog__list {
  @include ulres;

  display: grid;
  grid-template-columns: repeat(3, 270px);
  grid-gap: 65px 30px;
  margin-bottom: 70px;
}

.catalog__pic {
  display: block;
  width: 270px;
  height: 350px;
  margin-bottom: 20px;

  img {
    width: 270px;
    height: 350px;
    object-fit: cover;
  }
}

.catalog__title {
  margin: 0 0 10px;

  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: $text-main;

  a {
    color: $text-main;
    transition: $transition;

    &:hover,
    &:focus {
      opacity: 0.6;
    }
  }
}

.catalog__price {
  display: block;
  margin-bottom: 10px;

  font-size: 18px;
  line-height: 24px;
  font-weight: 500;
}

.catalog__pagination {
  margin-top: auto;
}