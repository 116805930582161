html {
  box-sizing: border-box;
}

*,
::before,
::after {
  box-sizing: inherit;
}

body {
  min-width: 320px;
  min-height: 100vh;
  margin: 0;
  background-color: #fff;
  display: flex;
  flex-direction: column;

  font-family: $font-main;
  font-size: 16px;
  line-height: 1.3;
  color: $text-main;
  -webkit-tap-highlight-color: transparent;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

img {
  max-width: 100%;
  height: auto;
  object-fit: contain;
}

a {
  text-decoration: none;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
}

.container {
  width: 1200px;
  margin: 0 auto;
  padding-left: 15px;
  padding-right: 15px;
}