@charset "UTF-8";
@font-face {
  font-display: swap;
  font-weight: 300;
  font-family: 'Geometria';
  font-style: normal;
  src: url("../fonts/Geometria-light.woff2") format("woff2"); }

@font-face {
  font-display: swap;
  font-weight: 400;
  font-family: 'Geometria';
  font-style: normal;
  src: url("../fonts/Geometria-Regular.woff2") format("woff2"); }

@font-face {
  font-display: swap;
  font-weight: 500;
  font-family: 'Geometria';
  font-style: normal;
  src: url("../fonts/Geometria-Medium.woff2") format("woff2"); }

@font-face {
  font-display: swap;
  font-weight: 700;
  font-family: 'Geometria';
  font-style: normal;
  src: url("../fonts/Geometria-Bold.woff2") format("woff2"); }

@font-face {
  font-display: swap;
  font-weight: 900;
  font-family: 'Geometria';
  font-style: normal;
  src: url("../fonts/Geometria-ExtraBold.woff2") format("woff2"); }

@font-face {
  font-display: swap;
  font-weight: 400;
  font-family: 'Circe';
  font-style: normal;
  src: url("../fonts/Circe-Regular.woff2") format("woff2"); }

@font-face {
  font-display: swap;
  font-weight: 700;
  font-family: 'Circe';
  font-style: normal;
  src: url("../fonts/Circe-Bold.woff2") format("woff2"); }

html {
  box-sizing: border-box; }

*,
::before,
::after {
  box-sizing: inherit; }

body {
  min-width: 320px;
  min-height: 100vh;
  margin: 0;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  font-family: "Geometria", "Arial", sans-serif;
  font-size: 16px;
  line-height: 1.3;
  color: #222;
  -webkit-tap-highlight-color: transparent;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

img {
  max-width: 100%;
  height: auto;
  object-fit: contain; }

a {
  text-decoration: none; }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0); }

.container {
  width: 1200px;
  margin: 0 auto;
  padding-left: 15px;
  padding-right: 15px; }

.button {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  background-color: transparent;
  -webkit-tap-highlight-color: transparent;
  display: inline-block;
  padding: 25px 15px;
  transition: all 0.2s ease;
  font-family: "Circe", "Arial", sans-serif;
  vertical-align: middle;
  text-transform: uppercase;
  font-weight: 700;
  cursor: pointer; }
  .button:disabled {
    opacity: 0.5;
    cursor: not-allowed; }
  .button--primery {
    background-color: #E02D71;
    border: none;
    color: #fff;
    font-size: 13px; }
    .button--primery:not(:disabled):hover, .button--primery:not(:disabled):focus {
      background-color: #150C26; }
  .button--second {
    background-color: transparent;
    border: 1px solid #222;
    padding: 15px;
    color: #222;
    font-size: 14px; }
    .button--second:not(:disabled):hover, .button--second:not(:disabled):focus {
      background-color: #222;
      color: #fff; }

.button-del {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  background-color: transparent;
  -webkit-tap-highlight-color: transparent;
  width: 20px;
  height: 20px; }

.header {
  background-color: #fff; }

.header__wrapper {
  display: grid;
  grid-template-columns: 380px 1fr  300px 25px;
  grid-column-gap: 40px;
  align-items: flex-end;
  justify-content: space-between;
  padding-top: 23px;
  padding-bottom: 23px;
  border-bottom: 1px solid #e9e9e9; }

.header__info {
  font-size: 12px;
  text-transform: uppercase; }

.header__logo {
  display: block;
  width: 190px;
  height: 33px;
  margin: 0 auto; }

.header__tel {
  margin-left: auto;
  font-size: 14px;
  color: #000; }

.header__cart {
  position: relative;
  height: 27px; }

.header__count {
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: #E02D71;
  padding: 3px;
  min-width: 13px;
  height: 13px;
  border-radius: 50%;
  font-size: 7px;
  line-height: 1;
  font-weight: 500;
  color: #fff;
  text-align: center; }

.content {
  flex-grow: 1;
  padding-top: 35px;
  padding-bottom: 100px; }

.content__top {
  margin-bottom: 35px; }
  .content__top--catalog {
    margin-left: 310px;
    text-align: center; }

.content__row {
  display: flex;
  align-items: center; }

.content__breadcrumbs {
  margin-bottom: 10px; }

.content__title {
  margin: 10px 0;
  font-weight: 800;
  font-size: 24px;
  line-height: 54px;
  text-transform: uppercase; }
  .content__title span {
    font-family: "Geometria", "Arial", sans-serif;
    font-weight: 500;
    font-size: 18px;
    vertical-align: top;
    color: #000; }

.content__info {
  margin-left: 12px;
  font-size: 16px;
  font-weight: 300;
  line-height: 1;
  color: #9D9D9D; }

.content__catalog {
  display: grid;
  grid-template-columns: 290px 1fr;
  grid-gap: 20px;
  align-items: flex-start; }

.filter__form {
  padding: 0 25px 60px; }

.filter__submit {
  width: 100%;
  margin-bottom: 15px; }

.filter__reset {
  width: 144px; }

.form__block {
  border: none;
  padding: 0;
  margin: 0;
  margin-bottom: 35px; }

.form__legend {
  margin-bottom: 12px;
  font-family: "Geometria", "Arial", sans-serif;
  font-size: 14px;
  line-height: 1;
  color: #737373; }

.form__label {
  position: relative;
  display: block;
  color: #222; }
  .form__label:not(:last-child) {
    margin-bottom: 25px; }
  .form__label--price::after {
    content: '₽';
    position: absolute;
    top: 32px;
    right: 20px;
    font-size: 16px;
    line-height: 1;
    color: #222; }
  .form__label--select::after {
    content: '';
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
    width: 14px;
    height: 7px;
    background-image: url("../img/svg/icon-arrow-bottom.svg");
    background-repeat: no-repeat;
    background-size: contain; }
  .form__label--small::after {
    right: 5px; }
  .form__label--small select {
    height: 35px;
    padding: 6px 25px 6px 10px; }

.form__value {
  position: absolute;
  top: 10px;
  left: 20px;
  font-size: 12px;
  line-height: 1;
  color: #737373;
  font-weight: 300; }

.form__input {
  padding: 28px 45px 13px 20px;
  width: 100%;
  height: 65px;
  border-radius: 1px;
  border: 1px solid transparent;
  background-color: #FAFAFA;
  box-shadow: none;
  transition: all 0.2s ease;
  color: #222;
  font-size: 16px;
  font-family: inherit;
  line-height: 1; }
  .form__input:hover, .form__input:focus {
    outline: none;
    border-color: #222; }
  .form__input--area {
    height: 140px;
    resize: none; }

.form__select {
  width: 100%;
  height: 52px;
  border-radius: 0;
  border: none;
  appearance: none;
  padding: 14px 35px 14px 18px;
  color: #222;
  font-size: 16px;
  font-family: inherit;
  line-height: 1; }

.form__counter {
  display: flex;
  align-items: center;
  width: 150px;
  height: 70px;
  background-color: #fff; }
  .form__counter button {
    margin: 0;
    padding: 0;
    border: 0;
    font: inherit;
    background-color: transparent;
    -webkit-tap-highlight-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border: 1px solid #E2E2E2;
    flex-shrink: 0;
    width: 37px;
    height: 37px;
    flex-shrink: 0;
    cursor: pointer;
    transition: all 0.2s ease; }
    .form__counter button:hover, .form__counter button:focus {
      background-color: #222;
      color: #fff; }
  .form__counter input {
    padding: 23px 5px;
    height: 70px;
    width: 70px;
    border-radius: 0;
    background-color: transparent;
    border: none;
    box-shadow: none;
    color: #222;
    font: inherit;
    font-size: 24px;
    line-height: 1;
    text-align: center; }

.form__error {
  position: absolute;
  bottom: -17px;
  left: 20px;
  font-size: 11px;
  line-height: 14px;
  color: #E02D71; }

.form__error-block {
  padding: 25px 30px;
  border: 3px solid #E02D71;
  border-radius: 1px; }
  .form__error-block h4 {
    margin: 0 0 4px;
    font-weight: 500;
    font-size: 17px;
    line-height: 24px;
    color: #E02D71; }
  .form__error-block p {
    margin: 0;
    font-size: 13px;
    color: #000; }

.colors {
  margin: 0;
  padding: 0;
  list-style: none;
  --border-color: #fff;
  display: flex;
  align-items: center;
  flex-wrap: wrap; }
  .colors--black {
    --border-color: #222; }

.colors__item:not(:last-child) {
  margin-right: 4px; }

.colors__label {
  position: relative;
  cursor: pointer;
  display: block;
  border-radius: 50%;
  padding: 3px; }

.colors__value {
  display: block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  transition: all 0.2s ease; }
  .colors__value::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 26px;
    height: 26px;
    border-radius: 50%;
    border: 1px solid transparent;
    transition: all 0.2s ease; }

.colors__label:hover .colors__value::before,
.colors__label:focus .colors__value::before,
.colors__radio:checked ~ .colors__value::before {
  border-color: var(--border-color); }

.colors__radio:focus ~ .colors__value::before {
  opacity: 0.7; }

.check-list {
  margin: 0;
  padding: 0;
  list-style: none; }

.check-list__item:not(:last-child) {
  margin-bottom: 13px; }

.check-list__label {
  display: block;
  cursor: pointer; }

.check-list__desc {
  position: relative;
  display: block;
  padding-left: 27px;
  padding-top: 3px;
  padding-bottom: 3px;
  font-size: 16px;
  line-height: 1; }
  .check-list__desc::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 12px;
    height: 12px;
    background-color: #fff;
    border: 1px solid #222222; }
  .check-list__desc::after {
    content: '';
    position: absolute;
    opacity: 0;
    top: 50%;
    left: 3px;
    width: 6px;
    height: 6px;
    transform: translateY(-50%);
    background-color: #E02D71;
    transition: all 0.2s ease; }
  .check-list__desc span {
    color: #737373; }

.check-list__label:hover .check-list__desc::after,
.check-list__label:focus .check-list__desc::after {
  opacity: 0.3; }

.check-list__check:checked ~ .check-list__desc::after {
  opacity: 1; }

.breadcrumbs {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
  flex-wrap: wrap; }

.breadcrumbs__item {
  position: relative; }
  .breadcrumbs__item:not(:last-child) {
    padding-right: 35px; }
    .breadcrumbs__item:not(:last-child)::after {
      content: '—';
      position: absolute;
      top: 50%;
      right: 13px;
      transform: translateY(-50%);
      color: #B9B9B9; }

.breadcrumbs__link {
  font-size: 14px;
  line-height: 1;
  color: #B9B9B9;
  transition: all 0.2s ease; }
  .breadcrumbs__link[href]:hover, .breadcrumbs__link[href]:focus {
    color: #222; }

.pics__wrapper {
  margin-bottom: 20px; }

.pics__list {
  margin: 0;
  padding: 0;
  list-style: none;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 20px; }

.pics__link {
  border-bottom: 3px solid transparent;
  transition: all 0.2s ease; }
  .pics__link:hover, .pics__link:focus {
    border-color: #E02D71; }
  .pics__link--current {
    border-color: #E02D71; }

.tabs {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #E7E7E7; }

.tabs__item:not(:last-child) {
  margin-right: 20px; }

.tabs__link {
  position: relative;
  display: block;
  padding: 15px 25px;
  font-size: 18px;
  color: #B9B9B9; }
  .tabs__link::after {
    content: '';
    position: absolute;
    bottom: -15px;
    left: 0;
    width: 100%;
    height: 3px;
    border-radius: 20px;
    background-color: transparent;
    transition: all 0.2s ease; }
  .tabs__link:hover::after, .tabs__link:focus::after {
    bottom: -1px;
    background-color: #E02D71; }
  .tabs__link--current {
    color: #222;
    font-weight: 700; }
    .tabs__link--current::after {
      bottom: -1px;
      background-color: #E02D71; }

.cart__field {
  grid-row: 1/3; }

.cart__list {
  margin: 0;
  padding: 0;
  list-style: none; }

.cart__item:not(:last-child) {
  margin-bottom: 60px; }

.cart__form {
  display: grid;
  grid-template-columns: 1fr 370px;
  grid-template-rows: auto 1fr;
  grid-gap: 20px 50px;
  align-items: flex-start; }

.cart__block {
  border: 1px solid #DEDEDE;
  border-radius: 1px;
  padding: 35px 30px; }

.cart__button {
  margin-top: 20px;
  width: 100%; }

.cart__desc {
  margin: 0 0 15px;
  font-size: 16px;
  line-height: 22px; }

.cart__price {
  margin: 0 0 15px;
  font-size: 30px;
  line-height: 48px; }
  .cart__price span {
    font-weight: 500;
    font-size: 34px;
    line-height: 48px; }

.cart__data {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 5px 30px;
  padding-bottom: 25px;
  margin-bottom: 15px; }
  .cart__data label:not(:nth-child(4)):not(:nth-child(3)) {
    grid-column: 1/-1; }

.cart__title {
  margin: 0 0 20px;
  font-family: "Circe", "Arial", sans-serif;
  font-size: 14px;
  line-height: 24px;
  text-transform: uppercase; }

.cart__options:not(:last-child) {
  margin-bottom: 40px; }

.cart__orders {
  margin: 0;
  padding: 0;
  list-style: none;
  padding: 0 30px 25px;
  margin: 0 -30px 25px;
  border-bottom: 1px solid #DEDEDE; }

.cart__order {
  display: grid;
  grid-template-columns: 1fr 90px;
  grid-gap: 5px 20px; }
  .cart__order:not(:last-child) {
    margin-bottom: 20px; }
  .cart__order h3 {
    margin: 0;
    font-size: 16px;
    line-height: 22px;
    font-weight: 400; }
  .cart__order b {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: right; }
  .cart__order span {
    font-size: 12px;
    line-height: 1;
    color: #9D9D9D; }

.cart__total p {
  margin: 0; }
  .cart__total p:not(:last-child) {
    margin-bottom: 5px; }

.cart__error {
  grid-column: 2/3; }

.cart__message {
  margin: 0 0 50px;
  font-size: 16px;
  line-height: 24px;
  color: #000; }

.product {
  display: grid;
  grid-template-columns: 120px 280px 140px auto 20px;
  grid-template-rows: repeat(3, min-content);
  align-items: center;
  grid-gap: 10px 20px; }

.product__pic {
  align-self: flex-start;
  grid-column: 1/2;
  grid-row: 1/4; }

.product__title {
  margin: 0;
  grid-column: 2/3;
  grid-row: 1/2;
  align-self: flex-start;
  font-size: 16px;
  line-height: 22px;
  font-weight: 400; }

.product__info {
  margin: 0;
  grid-column: 2/3;
  grid-row: 2/3;
  font-size: 14px;
  line-height: 1;
  color: #737373; }
  .product__info--color span {
    position: relative;
    display: inline-block;
    padding-left: 30px;
    vertical-align: top; }
    .product__info--color span i {
      position: absolute;
      top: 50%;
      left: 5px;
      transform: translateY(-50%);
      display: block;
      width: 20px;
      height: 20px;
      border-radius: 50%; }
  .product__info span {
    color: #222; }

.product__code {
  grid-column: 2/3;
  grid-row: 3/4;
  font-size: 12px;
  color: #B9B9B9; }

.product__del {
  grid-column: 5/6;
  grid-row: 1/2; }

.product__counter {
  width: 130px;
  height: 40px; }
  .product__counter input {
    padding: 10px 5px;
    height: 40px;
    width: 55px; }

.options {
  margin: 0;
  padding: 0;
  list-style: none;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 30px; }

.options__label {
  display: block;
  cursor: pointer; }

.options__value {
  position: relative;
  display: block;
  background-color: #fff;
  border: 1px solid #DEDEDE;
  border-radius: 2px;
  padding: 35px 30px;
  padding-left: 67px;
  font-size: 14px;
  line-height: 1;
  color: #222; }
  .options__value::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 35px;
    transform: translateY(-50%);
    width: 20px;
    height: 20px;
    border: 1px solid #222222;
    border-radius: 50%;
    transition: all 0.2s ease; }

.options__label:hover .options__value {
  background-color: rgba(224, 45, 113, 0.2); }
  .options__label:hover .options__value::before {
    border: 4px solid #fff; }

.options__radio:focus ~ .options__value {
  background-color: rgba(224, 45, 113, 0.2); }
  .options__radio:focus ~ .options__value::before {
    background-color: #222; }

.options__radio:checked ~ .options__value {
  background-color: #E02D71;
  color: #fff; }
  .options__radio:checked ~ .options__value::before {
    border: 4px solid #fff; }

.dictionary {
  margin: 0;
  padding: 0;
  list-style: none; }

.dictionary__item {
  display: grid;
  grid-template-columns: 170px 1fr;
  grid-gap: 20px;
  font-size: 16px;
  line-height: 24px; }
  .dictionary__item:not(:last-child) {
    margin-bottom: 30px; }

.dictionary__key {
  opacity: 0.6;
  font-weight: 300; }

.sizes {
  margin: 0;
  padding: 0;
  list-style: none;
  --text-color: #B9B9B9;
  --text-current: #222;
  display: flex;
  align-items: center; }
  .sizes--primery {
    --text-color: #898989;
    --text-current: #8BE000; }

.sizes__item:not(:last-child) {
  margin-right: 5px; }

.sizes__label {
  cursor: pointer;
  display: block; }

.sizes__value {
  display: block;
  transition: all 0.2s ease;
  min-width: 50px;
  font-size: 16px;
  line-height: 1;
  color: var(--text-color);
  text-transform: uppercase; }

.sizes__label:hover .sizes__value,
.sizes__label:focus .sizes__value {
  font-weight: 500;
  color: var(--text-current); }

.sizes__radio:checked ~ .sizes__value {
  font-weight: 500;
  color: var(--text-current); }

.sizes__radio:focus ~ .sizes__value {
  opacity: 0.7; }

.pagination {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center; }

.pagination__item:first-child {
  margin-right: 25px; }

.pagination__item:last-child {
  margin-left: 25px; }

.pagination__link {
  display: block;
  padding: 13px 3px;
  height: 40px;
  min-width: 40px;
  font-size: 16px;
  line-height: 1;
  color: #D1D1D1;
  text-align: center;
  transition: all 0.2s ease; }
  .pagination__link[href]:not(:disabled):hover, .pagination__link[href]:not(:disabled):focus {
    color: #222; }
  .pagination__link--disabled {
    opacity: 0.6;
    cursor: not-allowed; }
  .pagination__link--current {
    color: #222;
    font-weight: 700; }
  .pagination__link--arrow {
    border: 1px solid #E2E2E2;
    border-radius: 50%; }
    .pagination__link--arrow:hover, .pagination__link--arrow:focus {
      border-color: #222; }
  .pagination__link--disabled {
    opacity: 0.6;
    cursor: not-allowed; }
    .pagination__link--disabled:hover, .pagination__link--disabled:focus {
      border-color: #E2E2E2; }

.catalog {
  display: flex;
  flex-direction: column;
  align-self: stretch; }

.catalog__list {
  margin: 0;
  padding: 0;
  list-style: none;
  display: grid;
  grid-template-columns: repeat(3, 270px);
  grid-gap: 65px 30px;
  margin-bottom: 70px; }

.catalog__pic {
  display: block;
  width: 270px;
  height: 350px;
  margin-bottom: 20px; }
  .catalog__pic img {
    width: 270px;
    height: 350px;
    object-fit: cover; }

.catalog__title {
  margin: 0 0 10px;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #222; }
  .catalog__title a {
    color: #222;
    transition: all 0.2s ease; }
    .catalog__title a:hover, .catalog__title a:focus {
      opacity: 0.6; }

.catalog__price {
  display: block;
  margin-bottom: 10px;
  font-size: 18px;
  line-height: 24px;
  font-weight: 500; }

.catalog__pagination {
  margin-top: auto; }

.item {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 55px 30px;
  align-items: flex-start; }

.item__code {
  font-size: 14px;
  line-height: 1;
  color: #B9B9B9; }

.item__title {
  margin: 10px 0 22px;
  font-size: 40px;
  line-height: 50px;
  font-weight: 400; }

.item__price {
  display: block;
  font-weight: 500;
  font-size: 40px;
  line-height: 1; }

.item__sizes {
  color: #898989; }

.item__row {
  display: grid;
  grid-template-columns: repeat(2, auto);
  grid-gap: 20px;
  justify-content: flex-start; }
  .item__row--center {
    align-items: center; }

.item__desc {
  grid-column: 1/-1; }

.item__content {
  width: 595px;
  padding-top: 50px; }
  .item__content h3 {
    margin: 25px 0 5px;
    font-size: 16px;
    line-height: 24px;
    font-weight: 500; }
  .item__content p {
    margin: 0 0 20px;
    font-size: 16px;
    line-height: 28px;
    color: #222;
    font-weight: 300; }
  .item__content a {
    border-bottom: 2px dashed #222;
    transition: all 0.2s ease;
    font-size: 14px;
    line-height: 22px;
    color: #222; }
    .item__content a:hover, .item__content a:focus {
      border-color: transparent; }

.item__button {
  min-width: 200px; }

.social {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
  flex-wrap: wrap; }

.social__item + .social__item {
  margin-left: 30px; }

.social__link {
  transition: all 0.2s ease;
  color: #D9D9D9; }
  .social__link:hover, .social__link:focus {
    color: #E02D71; }

.footer__wrapper {
  display: grid;
  grid-template-columns: 550px 423px;
  justify-content: space-between;
  padding-top: 60px;
  padding-bottom: 60px;
  border-top: 1px solid #E9E9E9; }

.footer__link {
  font-size: 14px;
  line-height: 24px;
  color: #222;
  border-bottom: 2px solid transparent;
  transition: all 0.2s ease; }
  .footer__link:hover, .footer__link:focus {
    border-color: #E02D71; }
  .footer__link--medium {
    font-weight: 500; }

.footer__links {
  margin: 0;
  padding: 0;
  list-style: none;
  display: grid;
  grid-template-columns: repeat(3, auto);
  grid-gap: 20px 30px; }

.footer__desc {
  margin: 30px 0 37px;
  font-size: 14px;
  line-height: 24px;
  color: #9D9D9D; }

.footer__social {
  margin-left: auto;
  align-self: flex-start; }

.footer__copyright {
  grid-row: 3/4;
  align-self: flex-end;
  font-size: 12px; }

.footer__data {
  margin: 0;
  padding: 0;
  list-style: none;
  display: grid;
  grid-template-columns: repeat(2, auto);
  grid-gap: 10px;
  justify-content: space-between;
  grid-column: 2/3;
  grid-row: 3/4; }
