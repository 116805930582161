.cart__field {
  grid-row: 1/3;
}

.cart__list {
  @include ulres;
}

.cart__item {
  &:not(:last-child) {
    margin-bottom: 60px;
  }
}

.cart__form {
  display: grid;
  grid-template-columns: 1fr 370px;
  grid-template-rows: auto 1fr;
  grid-gap: 20px 50px;
  align-items: flex-start;
}

.cart__block {
  border: 1px solid #DEDEDE;
  border-radius: 1px;
  padding: 35px 30px;
}

.cart__button {
  margin-top: 20px;
  width: 100%;
}

.cart__desc {
  margin: 0 0 15px;

  font-size: 16px;
  line-height: 22px;
}

.cart__price {
  margin: 0 0 15px;

  font-size: 30px;
  line-height: 48px;

  span {
    font-weight: 500;
    font-size: 34px;
    line-height: 48px;
  }
}

.cart__data {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 5px 30px;
  padding-bottom: 25px;
  margin-bottom: 15px;

  label:not(:nth-child(4)):not(:nth-child(3)) {
    grid-column: 1/-1;
  }
}

.cart__title {
  margin: 0 0 20px;

  font-family: $font-second;
  font-size: 14px;
  line-height: 24px;
  text-transform: uppercase;
}

.cart__options {
  &:not(:last-child) {
    margin-bottom: 40px;
  }
}

.cart__orders {
  @include ulres;
  padding: 0 30px 25px;
  margin: 0 -30px 25px;
  border-bottom: 1px solid #DEDEDE;
}

.cart__order {
  display: grid;
  grid-template-columns: 1fr 90px;
  grid-gap: 5px 20px;

  &:not(:last-child) {
    margin-bottom: 20px;
  }

  h3 {
    margin: 0;

    font-size: 16px;
    line-height: 22px;
    font-weight: 400;
  }

  b {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: right;
  }

  span {
    font-size: 12px;
    line-height: 1;
    color: $text-second;
  }
}

.cart__total {
  p {
    margin: 0;
    &:not(:last-child) {
      margin-bottom: 5px;
    }
  }
}

.cart__error {
  grid-column: 2/3;
}

.cart__message {
  margin: 0 0 50px;
  
  font-size: 16px;
  line-height: 24px;
  color: #000;
}