.breadcrumbs {
  @include ulres;

  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.breadcrumbs__item {
  position: relative;

  &:not(:last-child) {
    padding-right: 35px;

    &::after {
      content: '—';
      position: absolute;
      top: 50%;
      right: 13px;
      transform: translateY(-50%);
      color: $text-light;
    }
  }
}

.breadcrumbs__link {
  font-size: 14px;
  line-height: 1;
  color: $text-light;
  transition: $transition;

  &[href]:hover,
  &[href]:focus {
    color: $text-main;
  }
}